<template>
    <div class="bg-lg" >
        <div class=" p-2">
            <router-view class=""></router-view>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        
    }
}
</script>

<style scoped>

.bg-lg {
    background-image: url(../../assets/svg/bgmobile.svg);
    background-repeat: no-repeat;
    background-size: cover;
}


@media (min-width: 640px) {
  .bg-lg {
    background-image: url(../../assets/svg/Group.svg);
    background-repeat: no-repeat;
    background-position: left bottom  ;
    background-size: cover;
  }
}


</style>